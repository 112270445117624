// local dependencies
import qs from 'qs';
import axios from 'axios';

// local dependencies
import { ENV } from '../constants/env';

const API_PATH = ENV.API_PATH;

const paramsSerializer = options => qs.stringify(options, { arrayFormat: 'repeat', encode: false });
export const API = axios.create({
  paramsSerializer,
  baseURL: API_PATH,
  withCredentials: false,
  headers: { 'Content-Type': 'application/json' },
});

// outsource dependencies
import React from "react";
import { useAccount } from "wagmi";
import { Box } from "@mui/material";

// local dependencies
import { UpdateWallet } from "./UpdateWallet";
import { UpdateConnect } from "./UpdateConnect";

export function UpdatePage ({ setopenModal }) {
  const { address } = useAccount();

  return <Box>
    {address ? <UpdateWallet /> : <UpdateConnect setopenModal={setopenModal} />}
  </Box>;
}

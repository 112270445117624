// outsource dependencies
import React, { useState } from "react";
import { Routes, Route } from "react-router-dom";

// local dependencies
import "./App.css";
import { ENV } from "./constants/env";
import { ChangeNetwork } from "./ChangeNetwork";
import { ClaimPage } from "./components/ClaimPage";
import { UpdatePage } from "./components/UpdatePage";
import { PaymentPage } from "./components/PaymentPage";
import { ConnectWalletModal } from "./ConnectWalletModal";
import {LinkAccount} from "./components/LinkAccountPage";
// import { LinkAccount } from "./components/LinkAccountPage";

ENV.ENV_TYPE !== 'production' && console.log('ENV', ENV);
function App () {
  const [openModal, setopenModal] = useState(false);

  return <>
    <ConnectWalletModal setOpen={setopenModal} open={openModal} />
    <ChangeNetwork />
    {/* <TransferStepper steps={['Initiated', 'Progress', 'Confirmed']} /> */}
    <Routes>
      <Route exact path="/" element={<PaymentPage setopenModal={setopenModal} />}/>
      <Route path="/claim" element={<ClaimPage setopenModal={setopenModal} />} />
      <Route path="/update" element={<UpdatePage setopenModal={setopenModal} />} />
      {/* link-account functionality was moved to BE side */}
      {/* <Route path="/link-account" element={<LinkAccount setopenModal={setopenModal} />} />*/}
    </Routes>
  </>;
}

export default App;

// outsource dependencies
import { useConnect } from "wagmi";
import React, { useState } from "react";
import { withStyles } from "@mui/styles";
import { Dialog, DialogContent, Typography, Box, useMediaQuery, Button } from "@mui/material";

// local dependencies
import { ToastNotify } from "./ToastNotify";

const StyledModal = withStyles(() => ({
  root: {
    "& .MuiDialog-root": {
      zIndex: "1301 !important",
    },
    "&.MuiDialog-container": {
      overflowY: "hidden !important",
    },
    "& .MuiDialog-paperScrollPaper": {
      backgroundColor: "tranparent !important",
      height: "auto",
      boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
    },
    "& .dialoge__content__section": {
      background: "#EDF1FE",
    },
    "& .MuiDialogContent-root": {
      padding: "20px",
    },
  },
}))(Dialog);

export function ConnectWalletModal ({ setOpen, open }) {
  const { connectAsync, connectors, isLoading, pendingConnector } = useConnect();
  const handleClose = () => { setOpen(false); };

  return <div className="modal__main__container">
    <StyledModal
      open={open}
      keepMounted
      onClose={handleClose}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogContent className="dialoge__content__section">
        <Box sx={{ textAlign: "center" }}>
          <Typography
            variant="h1"
            style={{
              color: "#000",
              fontSize: "28px",
              fontStyle: "normal",
              fontWeight: "bold",
              fontFamily: "ComicSans",
            }}
          >Connect to a wallet</Typography>
          <div>
            {connectors.map((connector) => <ConnectButton
              key={connector.id}
              isLoading={isLoading}
              connector={connector}
              handleClose={handleClose}
              connectAsync={connectAsync}
              pendingConnector={pendingConnector}
            />)}
          </div>
        </Box>
      </DialogContent>
    </StyledModal>
  </div>;
}

function ConnectButton ({ connectAsync, connector, isLoading, pendingConnector, handleClose }) {
  const matches = useMediaQuery("(max-width:600px)");
  const [alertState, setAlertState] = useState({ open: false, message: "", severity: null });

  const handleConnectWallet = async () => {
    try {
      await connectAsync({ connector });
      handleClose();
    } catch (error) {
      if (error.code === 'ACTION_REJECTED') {
        return setAlertState({ open: true, severity: "error", message: 'User rejected transaction' });
      }
      const errorMessage = error.reason || error.message || "Went something wrong";
      setAlertState({ open: true, severity: "error", message: errorMessage });
    }
  };
 
  return <>
    <ToastNotify alertState={alertState} setAlertState={setAlertState} />
    <Button
      key={connector.id}
      onClick={handleConnectWallet}
      disabled={!connector.ready || isLoading}
      sx={{
        padding: "5px",
        fontWeight: "700",
        cursor: "pointer",
        background: "#fff",
        margin: "20px auto",
        paddingLeft: "30px",
        paddingRight: "30px",
        borderRadius: "50px",
        alignItems: "center",
        border: "4px solid #eee",
        justifyContent: "center",
        width: matches ? "200px" : "300px",
        height: matches ? "45px" : "50px",
        fontSize: { md: "18px", xs: "14px" },
        "&:hover": { background: "#0071BDa1" },
        display: !connector.ready ? "none" : "flex",
      }}
    >
      <Typography
        variant="h1"
        style={{
          color: "#000",
          fontWeight: 500,
          fontStyle: "normal",
          textTransform: 'none',
          fontSize: matches ? "14px" : "18px",
        }}
      >
        {connector.name}
        {!connector.ready && " (unsupported)"}
        {isLoading && connector.id === pendingConnector?.id && " (connecting)"}
      </Typography>
    </Button>
  </>;
}

// outsource dependencies
import Web3 from "web3";
import React, { useEffect, useState } from "react";
import { Dialog, DialogContent, Box, Slide } from "@mui/material";

// local dependencies
import { ENV } from "./constants/env";

const Transition = React.forwardRef(function Transition (props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const web3 = new Web3(Web3.givenProvider || ENV.MAINNET_INFURA_URL);
const chain = async (setSwitchNetwork) => {
  if (!window.web3) { return; }
  try {
    const chainid = await web3.eth.getChainId();
    console.log(chainid, "chainid");
    if (Number(chainid) !== 80002) {
      setSwitchNetwork(true);
    }/* 
    if (Number(chainid) !== 1) {
      setSwitchNetwork(true);
    } */
  } catch (error) {
    console.log("Chain error");
  }
};

export function ChangeNetwork () {
  const [switchNetwork, setSwitchNetwork] = useState(false);
  useEffect(() => { chain(setSwitchNetwork); }, []);
  const handleClose = () => setSwitchNetwork(false);
  const handleChangeNetwork = async () => {
    try {
      await window.ethereum.request({
        method: "wallet_switchEthereumChain",
        // params: [{ chainId: "0x13882" }], //testnet - chainId: 80002
        params: [{ chainId: "0x1" }], //mainnet - chainId: 1
      });
      handleClose();
    } catch (error) {
      console.log(error);
    }
  };
  return <div className="modal__main__container">
    <Dialog
      keepMounted
      open={switchNetwork}
      onClose={handleClose}
      TransitionComponent={Transition}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogContent className="dialoge__content__section">
        <Box color="#000" sx={{ mb: 2, textAlign: 'center' }}>
          <Box component="span" color="red" fontSize="30px">Warning!</Box>
          <Box>You are on wrong network. Switch your network please.</Box>
        </Box>
        <Box textAlign="center">
          <button
            onClick={handleChangeNetwork}
            style={{
              background: "#0071BD",
              fontFamily: "Inter",
              borderRadius: "34px",
              padding: "12px 20px",
              border: "none",
              outline: "none",
              color: "#fff",
              cursor: "pointer",
              fontSize: "16px",
              lineHeight: "19px",
              fontWeight: "bolder",
              textTransform: "uppercase",
              "&:hover": { background: "#0071BDa1" },
            }}
          >Switch Network</button>
        </Box>
      </DialogContent>
    </Dialog>
  </div>;
}

// outsource dependencies
import React from "react";
import { ethers } from "ethers";
import { useSigner } from "wagmi";
import { Contract } from "@ethersproject/contracts";

// local dependencies
import { ENV } from "../constants/env";
import { tokenAbi } from "./tokenAbi.js";
import { contractAbi } from "./contractAbi.js";

function useContract (address, ABI) {
  const { data: signer } = useSigner();
  return React.useMemo(() => new Contract(address, ABI, signer), [address, ABI, signer]);
}

export function useTokenContract () {
  return useContract(ENV.TOKEN_ADDRESS, tokenAbi);
}

export function useMLMContract () {
  return useContract(ENV.CONTRACT_ADDRESS, contractAbi);
}

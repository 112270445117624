// outsource dependencies
import React from "react";
import { useAccount } from "wagmi";
import { Box } from "@mui/material";

// local dependencies
import { ClaimMain } from "./ClaimMain";
import ClaimConnect from "./ClaimConnect";

export function ClaimPage ({ setopenModal }) {
  const { address } = useAccount();

  return <Box>
    {address ? <ClaimMain /> : <ClaimConnect setopenModal={setopenModal} />}
  </Box>;
}

// outsource dependencies
import axios from "axios";
import moment from "moment";
import * as React from "react";
import Button from "@mui/material/Button";
import { useAccount, useSigner } from "wagmi";
import Typography from "@mui/material/Typography";
import { Box, Grid, Container } from "@mui/material";
import { formatUnits, parseUnits } from "@ethersproject/units";

// local dependencies
import Loading from "../Loader/Loader";
import { ToastNotify } from "../../ToastNotify";
import { gasEstimationForAll } from "../../utils";
import CircularProgress from "@mui/material/CircularProgress";
import { useMLMContract, useTokenContract } from "../../ConnectivityAss/hooks";
import { ENV } from "../../constants/env";

// config
const steps = ["Approve", "Progress", "Transfer Amount"];

export default function HorizontalLinearStepper ({ actualdata, taxFee, paymentState }) {
  const mainData = actualdata;
  const { address } = useAccount();
  const { data: signer } = useSigner();
  const MLMContract = useMLMContract(signer);
  const TokenContract = useTokenContract(signer);
  const [activeStep, setActiveStep] = React.useState(0);
  const [loading, setloading] = React.useState(false);
  const [skipped, setSkipped] = React.useState(new Set());
  const today = moment();
  const [alertState, setAlertState] = React.useState({
    open: false,
    message: "",
    severity: undefined,
  });

  const approveHandler = async () => {
    try {
      // setloading(true);
      handleNext();
      const balance = await TokenContract.balanceOf(address);
      console.log(balance);
      const allowance = await TokenContract.allowance(address, ENV.CONTRACT_ADDRESS);
      console.log(+formatUnits(allowance.toString(), 6), mainData.amount);
      if (+formatUnits(allowance.toString(), 6) < mainData.amount) {
        const fn = TokenContract.estimateGas.approve;
        const data = [ENV.CONTRACT_ADDRESS, parseUnits(mainData.amount.toString(), 6)];
        const tx = await TokenContract.approve(
          ENV.CONTRACT_ADDRESS,
          parseUnits(mainData.amount.toString(), 6),
          {
            gasLimit: gasEstimationForAll(address, fn, data),
          }
        );
        await tx.wait();
        setAlertState({
          open: true,
          severity: "success",
          message: `Approved Successfully`,
        });
      }
      transferHandler();
      // setloading(false);
    } catch (error) {
      console.log(error, "error");
      try {
        await axios.post("/logs", {
          functionName: "approve",
          error: JSON.stringify(error),
          address: address,
        });
      } catch (error) {
        console.log(error);
      }
      if (error?.message) {
        setAlertState({
          open: true,
          message: error?.message,
          severity: "error",
        });
      } else {
        setAlertState({
          open: true,
          message: error?.data?.message,
          severity: "error",
        });
        setloading(false);
      }
    }
  };
  const transferHandler = async () => {
    console.log(
      '"mainData.index_id,',
      parseUnits(mainData?.value.toString(), 6).toString(),
      mainData.username,
      mainData.index_id + mainData?.value
    );
    try {
      // setloading(true);
      const fn = MLMContract.estimateGas.deposit;
      const data1 = [
        parseUnits(mainData?.value.toString(), 6).toString(),
        mainData.username,
        mainData.index_id + mainData?.value,
      ];
      const tx = await MLMContract.deposit(
        parseUnits(mainData?.value.toString(), 6),
        mainData.username,
        mainData.index_id + mainData?.value,
        {
          gasLimit: gasEstimationForAll(address, fn, data1),
        }
      );
      await tx.wait();
      setAlertState({
        open: true,
        message: `Transfered Successfully`,
        severity: "success",
      });

      const paymentDate = today.format("DD/MM/YYYY");
      const hours = today.format("hh:mm");
      const body = {
        username: mainData.username,
        // eslint-disable-next-line camelcase
        wallet_address: mainData.wallet_Address,
        data: mainData.date,
        ora: hours,
        // eslint-disable-next-line camelcase
        data_inizio: paymentDate,
        // eslint-disable-next-line camelcase
        tipo_pagamento: "CRYPTO",
        // eslint-disable-next-line camelcase
        codice_prodotto: mainData.product_id,
        descrizione: "Test",
        valore: mainData?.value.toString(),
        // eslint-disable-next-line camelcase
        e_id: mainData.order_id,
        mesi: mainData.months,
      };
      const { data } = await axios.post("/insertOrder", body);
      console.log(data);
      if (data?.codice === "OK") {
        setAlertState({
          open: true,
          message: data.descrizione,
          severity: "info",
        });
        window.location.replace("https://sea.datexit.com/miei_piani.php");
      } else {
        setAlertState({
          open: true,
          message: data.descrizione,
          severity: "error",
        });
      }
      const inputs = {
        username: mainData.username,
        stand_by: "0",
      };
      if (paymentState === false) {
        const result = await axios.post("/modAgent", inputs);
        if (result?.data.codice !== "OK") {
          setAlertState({
            open: true,
            message: result.data.descrizione,
            severity: "error",
          });
        }
      }
      handleNext();
      setloading(false);
    } catch (error) {
      console.log(error, "func_error");
      if (error?.data?.message) {
        setAlertState({
          open: true,
          message: error?.message,
          severity: "error",
        });
      } else if (error?.reason) {
        setAlertState({
          open: true,
          message: error?.reason,
          severity: "error",
        });
      } else {
        setAlertState({
          open: true,
          message: error?.message,
          severity: "error",
        });
      }
      await axios.post("/logs", {
        functionName: "deposit",
        error: JSON.stringify(error),
        address: address,
      });
      setloading(false);
    }
  };

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  return (
    <Box
      mb={5}
      sx={{
        backgroundColor: "white",
        width: "100%",
        color: "black",
      }}
    >
      <Loading loading={loading} />
      <ToastNotify alertState={alertState} setAlertState={setAlertState} />
      <Container>
        <Box
          mt={2}
          sx={{
            color: "black",
            fontSize: { md: "16px", xs: "14px" },
            fontFamily: "Inter",
            textAlign: "center",
          }}
          display="flex"
          justifyContent={"center"}
        >
          {activeStep === steps.length ? (
            "Transfered Successfully"
          ) : (
            <Box width={{ xs: "100%", sm: "90%" }}>
              Transfer in progress in 2 different steps.
              <br />- Connection and Transfer - <br />
              <Box>
                {" "}
                Please wait up to 3 minutes to complete the processes. Do not
                close or refresh this page.{" "}
              </Box>
              <br />
              <Grid container spacing={3} sx={{ textAlign: "left" }}>
                <Grid item xs={4} sm={5} md={5}>
                  <Box>Program </Box>{" "}
                </Grid>
                <Grid item xs={8} sm={7} md={7}>
                  <Box>Amount {mainData ? mainData.value : "0"} USDT</Box>
                </Grid>
              </Grid>
              <Grid container spacing={3} sx={{ textAlign: "left" }}>
                <Grid item xs={4} sm={5} md={5}>
                  <Box>Fee </Box>{" "}
                </Grid>
                <Grid item xs={8} sm={7} md={7}>
                  <Box>Amount {taxFee} USDT </Box>
                </Grid>
              </Grid>
              <Grid container spacing={3} sx={{ textAlign: "left" }}>
                <Grid item xs={4} sm={5} md={5}>
                  <Box>Total Transfer </Box>{" "}
                </Grid>
                <Grid item xs={8} sm={7} md={7}>
                  <Box> Amount {mainData ? mainData.amount : "0"} USDT</Box>
                </Grid>
              </Grid>
            </Box>
          )}
        </Box>

        {activeStep === steps.length && (
          <React.Fragment>
            <Typography sx={{ mt: 2, mb: 1 }}>
              Successfully transfered - you&apos;re finished
            </Typography>
          </React.Fragment>
        )}
        {activeStep === 1 && (
          <React.Fragment>
            <Box textAlign="center" sx={{ mt: 5, mb: 3 }}>
              <CircularProgress color="inherit" />
            </Box>
          </React.Fragment>
        )}
        {activeStep === 0 && (
          <React.Fragment>
            <Box pt={4} textAlign="center">
              <Box
                mt={3}
                sx={{
                  color: "black",
                  fontWeight: "bold",
                  fontSize: { md: "20px", xs: "16px" },
                  fontFamily: "Inter",
                }}
              >
                Transfer
              </Box>
              <Box
                pt={2}
                sx={{
                  color: "black",
                  width: "70%",
                  margin: "auto",
                  fontSize: { md: "20px", xs: "15px" },
                  fontFamily: "Inter",
                }}
              >
                Are you sure to transfer?
              </Box>
              <Box marginTop="10%" textAlign="center" color="black">
                <Button
                  onClick={() => approveHandler()}
                  sx={{
                    background: "#2887C5",
                    color: "white",
                    fontSize: { md: "20px", xs: "18px" },
                    fontFamily: "Inter",
                    padding: "5px 12px 5px 12px",
                    borderRadius: "10px",
                    textTransform: "capitalize",
                    "&:hover": {
                      background: "#2887C5a1",
                    },
                  }}
                >
                  Transfer
                </Button>
              </Box>
            </Box>
          </React.Fragment>
        )}
      </Container>
    </Box>
  );
}

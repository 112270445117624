// outsource dependencies
import React from "react";
import { Box, Container, useMediaQuery, Button } from "@mui/material";

function ClaimConnect ({ setopenModal }) {
  const matches = useMediaQuery("(min-width : 630px)");

  return <Container>
    <Box pt={8}>
      <Container maxWidth="md">
        <Box
          py={4}
          boxShadow={3}
          bgcolor="#EDF1FE"
          borderRadius="5px"
          px={matches ? 6 : 4}
        >
          <Box
            textAlign="center"
            fontSize={{ md: "30px", xs: "22px" }}
            fontFamily="Inter"
            fontWeight="500"
            color="black"
          >SE@ Payment System</Box>
          <Box textAlign="center" my={5}>
            <img src="images/seaLogo.png" width={matches ? "90px" : "110px"} height={matches ? "90px" : "110px"} alt=""/>

            <Box
              my={5}
              fontSize={{ xs: "20px", sm: "25px" }}
              fontWeight={400}
              color="#000"
            >Please connect using your wallet to claim your rewards</Box>
            <Button
              onClick={() => setopenModal(true)}
              sx={{
                width: "90%",
                color: "white",
                cursor: "pointer",
                fontWeight: "700",
                fontFamily: "Inter",
                background: "#0071BD",
                fontSize: { md: "18px", xs: "14px" },
                "&:hover": { background: "#0071BDa1" },
              }}
            >connect</Button>
          </Box>
        </Box>
      </Container>
    </Box>
  </Container>;
}

export default ClaimConnect;

// outsource dependencies
import React, { useState, useEffect } from 'react';
import { Stepper, Step, StepLabel, Box, Typography, Button, Paper, Dialog, DialogContent, } from '@mui/material';

// local dependencies
import tickImage from '../images/tick.png';

export const TransferStepper = ({ steps = [], amount = 0, title = "", description = "", isOpened=false, handleWait=null, onClose = () => void (0), activeStep, setActiveStep }) => {
  const [stateStep, setStateStep] = useState(1); // Set initial step to 1 so that the first step is completed

  const handleClose = () => {
    onClose();
  };

  const step = activeStep || stateStep;
  const setStep = setActiveStep || setStateStep;

  return <Dialog open={isOpened} TransitionProps={{ onExited: () => setStep(1) }}>
    <DialogContent sx={{ p: 0, width: 440, textAlign: 'center' }}>
      <Paper elevation={0} sx={{ p: 4, textAlign: 'center' }}>
        {title && <Typography variant="h6" gutterBottom sx={{ fontSize: 26 }}>{title}</Typography>}
        {amount > 0 && <Typography variant="body1" sx={{ mb: 3 }}>Transfer Amount: {amount} USDT</Typography>}
        <ProcessStep
          steps={steps}
          activeStep={step}
          isOpened={isOpened}
          length={steps.length}
          handleWait={handleWait}
          setActiveStep={setStep}
          handleClose={handleClose}
        />
        {step === steps.length ? <Box sx={{ mt: 4 }}>
          <img src={tickImage} alt="tick" width={93} height={110} />
          <Typography variant="h6" sx={{ fontWeight: 600, fontSize: 20 }}>Transfer Completed</Typography>
          <Typography variant="body2" sx={{ mb: 8, fontSize: 16 }}>
            {description && description}
          </Typography>
          <Button variant="contained" onClick={handleClose}>Done</Button>
        </Box> : <Box sx={{ mt: 4 }}>
          {step === 1
            ? <Typography variant="body2">Processing...</Typography>
            : <Button variant="contained" onClick={() => setStep((prevActiveStep) => prevActiveStep + 1)}>
              {step === steps.length - 1 ? 'Finish' : 'Next'}
            </Button> }
        </Box>}
      </Paper>
    </DialogContent>
  </Dialog>;
};

function ProcessStep ({ isOpened=false, activeStep, handleWait, setActiveStep, steps, handleClose }) {
  const length = steps.length;

  useEffect(() => {
    if (!isOpened) { return; }
    if (activeStep === 1 && handleWait) {
      (async () => {
        const error = await handleWait();
        if (error) {
          return handleClose();
        }
        setActiveStep(length);
      })();
    }
  }, [activeStep, handleClose, handleWait, isOpened, length, setActiveStep]);

  return <Stepper activeStep={activeStep} alternativeLabel>
    {steps.map((label) => <Step key={label}>
      <StepLabel>{label}</StepLabel>
    </Step>)}
  </Stepper>;
}

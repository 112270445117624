// outsource dependencies
import { Alert, Snackbar } from "@mui/material";

export function ToastNotify ({ alertState, setAlertState }) {
  return <Snackbar
    key={"top center"}
    open={alertState.open}
    autoHideDuration={3000}
    anchorOrigin={{ vertical: "top", horizontal: "center" }}
    onClose={() => setAlertState({ ...alertState, open: false })}
  >
    <Alert
      severity={alertState.severity}
      onClose={() => setAlertState({ ...alertState, open: false })}
    >
      {alertState.message}
    </Alert>
  </Snackbar>;
}
